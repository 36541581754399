export const ADD_BUILDING = "ADD_BUILDING";
export const DELETE_BUILDING = "DELETE_BUILDING";
export const EDIT_BUILDING = "EDIT_BUILDING";
export const BUILDING_LOADING = "BUILDING_LOADING";
export const LOAD_BUILDING = "LOAD_BUILDING";
export const LOAD_BUILDING_ERROR = "LOAD_BUILDING_ERROR";

export const BOT_APP_CONFIG = "BOT_APP_CONFIG";
export const LOAD_CHAT = "LOAD_CHAT";

export const ADD_MESSAGE = "ADD_MESSAGE";
