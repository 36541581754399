import { configureStore } from "@reduxjs/toolkit";
// import thunkMiddleware from "redux-thunk";
import { building, botApp, chat } from "./reducers";

const store = configureStore({
  reducer: {
    building,
    botApp,
    chat,
  },
  // middleware: (getDefaultMiddleware) => {
  //   return getDefaultMiddleware().concat(thunkMiddleware);
  // },
  // devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
